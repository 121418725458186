import React from "react";
import { Header, Grid, Image } from "semantic-ui-react";
import Router from "next/router";

const styles = {
  Column: {
    cursor: "pointer",
  },
  SubHeader: {},
  Header: {
    marginTop: "7px",
  },
};

const Trend = (props) => {
  const toServices = () => {
    let url = "/serviceproviders?";
    let fields = {
      sortBy: "",
      searchFor: "",
      postcode: "",
      distance: "",
      priceRange: "",
      when: "",
    };
    Router.push(url);
  };
  return (
    <>
      <Grid.Column style={styles.Column} onClick={toServices}>
        <div
          style={{
            width: "100%",
            height: "200px",
            overflow: "hidden",
            backgroundSize: "cover",
            backgroundImage: `url(${props.pictureUrl})`,
          }}
        >
          {/* <Image className="has-full-width" src={} /> */}
        </div>
        <Header style={styles.Header} as="h4">
          {props.serviceName}
        </Header>
      </Grid.Column>
    </>
  );
};

export default Trend;
